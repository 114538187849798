import React, { lazy } from 'react';
import { FormFullWidthLayout } from 'pages/Forms/components/FormLayout';

const ReactLazyPreload = (importStatement) => {
  const Component: React.LazyExoticComponent<React.ComponentType<any>> & {
    preload?: () => any;
  } = lazy(importStatement);
  Component.preload = importStatement;
  return Component;
};

const StartForm = ReactLazyPreload(() => import('pages/Forms/Welcome/Start'));
const TVStartForm = ReactLazyPreload(
  () => import('pages/Forms/Welcome/Start/TVStart')
);

const ApplicationIntroForm = ReactLazyPreload(
  () => import('pages/Forms/Application/Intro')
);
const ApplicationPurposeForm = ReactLazyPreload(
  () => import('pages/Forms/Application/Purpose')
);
const ApplicationPreferencesForm = ReactLazyPreload(
  () => import('pages/Forms/Application/Preferences')
);
const ApplicationDetailsForm = ReactLazyPreload(
  () => import('pages/Forms/Application/Details')
);
const ApplicationApplicantsForm = ReactLazyPreload(
  () => import('pages/Forms/Application/Applicants')
);
const ApplicationEquityReleaseForm = ReactLazyPreload(
  () => import('pages/Forms/Application/EquityRelease')
);
const ApplicationNewBuildForm = ReactLazyPreload(
  () => import('pages/Forms/Application/NewBuild')
);
const ApplicationReviewForm = ReactLazyPreload(
  () => import('pages/Forms/Application/Review')
);
export const ApplicationSectionScreens = [
  ApplicationIntroForm,
  ApplicationPurposeForm,
  ApplicationPreferencesForm,
  ApplicationDetailsForm,
  ApplicationApplicantsForm,
  ApplicationEquityReleaseForm,
  ApplicationNewBuildForm,
  ApplicationReviewForm,
];

const TVApplicationIntroForm = ReactLazyPreload(
  () => import('pages/Forms/TVApplication/Intro')
);
const TVApplicationPreferencesForm = ReactLazyPreload(
  () => import('pages/Forms/TVApplication/Preferences')
);
const TVApplicationOptionsForm = ReactLazyPreload(
  () => import('pages/Forms/TVApplication/Options')
);
const TVApplicationQuestionsForm = ReactLazyPreload(
  () => import('pages/Forms/TVApplication/Questions')
);
const TVApplicationReviewForm = ReactLazyPreload(
  () => import('pages/Forms/TVApplication/Review')
);
export const TVApplicationSectionScreens = [
  TVApplicationIntroForm,
  TVApplicationPreferencesForm,
  TVApplicationOptionsForm,
  TVApplicationQuestionsForm,
  TVApplicationReviewForm,
];

const TVPropertyIntroForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/Intro')
);
const TVPropertyDetailsForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/Details')
);
const TVPropertyAddressForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/Address')
);
const TVPropertyUsageForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/Usage')
);
const TVPropertyOtherAreasForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/OtherAreas')
);
const TVPropertyAdditionalDetailsForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/AdditionalDetails')
);
const TVPropertyParkingForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/Parking')
);
const TVPropertyUserValuationForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/UserValuation')
);
const TVPropertyRestrictionsForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/Restrictions')
);
const TVPropertyLoanForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/PropertyLoan')
);
const TVPropertyOwnershipForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/Ownership')
);
const TVPropertyReviewForm = ReactLazyPreload(
  () => import('pages/Forms/TVProperty/Review')
);
export const TVPropertySectionScreens = [
  TVPropertyIntroForm,
  TVPropertyDetailsForm,
  TVPropertyAddressForm,
  TVPropertyUsageForm,
  TVPropertyOtherAreasForm,
  TVPropertyAdditionalDetailsForm,
  TVPropertyParkingForm,
  TVPropertyUserValuationForm,
  TVPropertyRestrictionsForm,
  TVPropertyLoanForm,
  TVPropertyOwnershipForm,
  TVPropertyReviewForm,
];

const MainPropertyIntroForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Intro')
);
const MainPropertyDetailsForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Details')
);
const MainPropertyAddressForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Address')
);
const MainPropertyUsageForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Usage')
);
const MainPropertyRentedForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Rented')
);
const MainPropertyValuationForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/UserValuation')
);
const MainPropertyRestrictionsForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Restrictions')
);
const MainPropertyLeaseholdForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Leasehold')
);
const MainPropertyLandRegisterForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/LandRegister')
);
const MainPropertyConstructionForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Construction')
);
const MainPropertyHouseForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/HouseDetails')
);
const MainPropertyApartment1Form = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/ApartmentDetails1')
);
const MainPropertyApartment2Form = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/ApartmentDetails2')
);
const MainPropertyBuildingsForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/BuildingsDetails')
);
const MainPropertyBuildingsUnitsForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/BuildingsUnits')
);
const MainPropertyUnitsResidentialForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/UnitsResidential')
);
const MainPropertyUnitsCommercialForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/UnitsCommercial')
);
const MainPropertyBathroomForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Bathroom')
);
const MainPropertyFacilitiesForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Facilities')
);
const MainPropertyReviewForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDetails/Review')
);
export const MainPropertySectionScreens = [
  MainPropertyIntroForm,
  MainPropertyDetailsForm,
  MainPropertyAddressForm,
  MainPropertyUsageForm,
  MainPropertyRentedForm,
  MainPropertyValuationForm,
  MainPropertyRestrictionsForm,
  MainPropertyLeaseholdForm,
  MainPropertyLandRegisterForm,
  MainPropertyConstructionForm,
  MainPropertyHouseForm,
  MainPropertyApartment1Form,
  MainPropertyApartment2Form,
  MainPropertyBuildingsForm,
  MainPropertyBuildingsUnitsForm,
  MainPropertyUnitsResidentialForm,
  MainPropertyUnitsCommercialForm,
  MainPropertyBathroomForm,
  MainPropertyFacilitiesForm,
  MainPropertyReviewForm,
];

const PropertyDebtIntroForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDebt/Intro')
);
const PropertyDebtOverviewForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDebt/DebtOverview')
);
const PropertyDebtMortgage1Form = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDebt/Mortgage1')
);
const PropertyDebtMortgage2Form = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDebt/Mortgage2')
);
const PropertyDebtConstructionLoanForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDebt/ConstructionLoan')
);
const PropertyDebtReviewForm = ReactLazyPreload(
  () => import('pages/Forms/MainPropertyDebt/Review')
);
export const PropertyDebtSectionScreens = [
  PropertyDebtIntroForm,
  PropertyDebtOverviewForm,
  PropertyDebtMortgage1Form,
  PropertyDebtMortgage2Form,
  PropertyDebtConstructionLoanForm,
  PropertyDebtReviewForm,
];

const RealEstateIntroForm = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Intro')
);
const RealEstateNumberForm = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Number')
);
const RealEstateAddress1Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Address1')
);
const RealEstateDetails1Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Details1')
);
const RealEstateUsage1Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Usage1')
);
const RealEstateLoan1Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Loan1')
);
const RealEstateAddress2Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Address2')
);
const RealEstateDetails2Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Details2')
);
const RealEstateUsage2Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Usage2')
);
const RealEstateLoan2Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Loan2')
);
const RealEstateAddress3Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Address3')
);
const RealEstateDetails3Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Details3')
);
const RealEstateUsage3Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Usage3')
);
const RealEstateLoan3Form = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Loan3')
);
const RealEstateReviewForm = ReactLazyPreload(
  () => import('pages/Forms/RealEstate/Review')
);
export const RealEstateSectionScreens = [
  RealEstateIntroForm,
  RealEstateNumberForm,
  RealEstateAddress1Form,
  RealEstateDetails1Form,
  RealEstateUsage1Form,
  RealEstateLoan1Form,
  RealEstateAddress2Form,
  RealEstateDetails2Form,
  RealEstateUsage2Form,
  RealEstateLoan2Form,
  RealEstateAddress3Form,
  RealEstateDetails3Form,
  RealEstateUsage3Form,
  RealEstateLoan3Form,
  RealEstateReviewForm,
];

const MainApplicantIntroForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Intro')
);
const MainApplicantNameForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Name')
);
const MainApplicantContactForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Contact')
);
const MainApplicantPersonalForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Personal')
);
const MainApplicantTaxesForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Taxes')
);
const MainApplicantFamilyForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Family')
);
const MainApplicantChildrenForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Children')
);
const MainApplicantResidenceStatusForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/ResidenceStatus')
);
const MainApplicantResidenceStatusATForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/ResidenceStatusAT')
);
const MainApplicantResidenceAddressForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/ResidenceAddress')
);
const MainApplicantEmploymentForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Employment')
);
const MainApplicantSelfEmployedForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/SelfEmployed')
);
const MainApplicantEmploymentTemporaryForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/EmploymentTemporary')
);
const MainApplicantEmploymentTrialForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/EmploymentTrial')
);
const MainApplicantIncomeForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Income')
);
const MainApplicantAdditionalIncomeForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/AdditionalIncome')
);
const MainApplicantMonthlyCostsForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/MonthlyCosts')
);
const MainApplicantPensionForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Pension')
);
const MainApplicantInsuranceForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Insurance')
);
const MainApplicantInsuranceAdditionalForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/InsuranceAdditional')
);
const MainApplicantCarsForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Cars')
);
const MainApplicantLeasingForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Leasing')
);
const MainApplicantLoansForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Loans')
);
const MainApplicantLoan1Form = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Loan1')
);
const MainApplicantLoan2Form = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Loan2')
);
const MainApplicantLoan3Form = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Loan3')
);
const MainApplicantAssetsForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Assets')
);
const MainApplicantReviewForm = ReactLazyPreload(
  () => import('pages/Forms/MainApplicant/Review')
);
export const MainApplicantSectionScreens = [
  MainApplicantIntroForm,
  MainApplicantNameForm,
  MainApplicantContactForm,
  MainApplicantPersonalForm,
  MainApplicantTaxesForm,
  MainApplicantFamilyForm,
  MainApplicantChildrenForm,
  MainApplicantResidenceStatusForm,
  MainApplicantResidenceStatusATForm,
  MainApplicantResidenceAddressForm,
  MainApplicantEmploymentForm,
  MainApplicantSelfEmployedForm,
  MainApplicantEmploymentTemporaryForm,
  MainApplicantEmploymentTrialForm,
  MainApplicantIncomeForm,
  MainApplicantAdditionalIncomeForm,
  MainApplicantMonthlyCostsForm,
  MainApplicantPensionForm,
  MainApplicantInsuranceForm,
  MainApplicantInsuranceAdditionalForm,
  MainApplicantCarsForm,
  MainApplicantLeasingForm,
  MainApplicantLoansForm,
  MainApplicantLoan1Form,
  MainApplicantLoan2Form,
  MainApplicantLoan3Form,
  MainApplicantAssetsForm,
  MainApplicantReviewForm,
];

const TVMainApplicantIntroForm = ReactLazyPreload(
  () => import('pages/Forms/TVMainApplicant/Intro')
);
const TVMainApplicantNameForm = ReactLazyPreload(
  () => import('pages/Forms/TVMainApplicant/Name')
);
const TVMainApplicantContactDetailsForm = ReactLazyPreload(
  () => import('pages/Forms/TVMainApplicant/ContactDetails')
);
const TVMainApplicantDetailsForm = ReactLazyPreload(
  () => import('pages/Forms/TVMainApplicant/Details')
);
const TVMainApplicantEmploymentForm = ReactLazyPreload(
  () => import('pages/Forms/TVMainApplicant/Employment')
);
const TVMainApplicantResidenceAddressForm = ReactLazyPreload(
  () => import('pages/Forms/TVMainApplicant/ResidenceAddress')
);
const TVMainApplicantReviewForm = ReactLazyPreload(
  () => import('pages/Forms/TVMainApplicant/Review')
);
export const TVMainApplicantSectionScreens = [
  TVMainApplicantIntroForm,
  TVMainApplicantNameForm,
  TVMainApplicantContactDetailsForm,
  TVMainApplicantDetailsForm,
  TVMainApplicantEmploymentForm,
  TVMainApplicantResidenceAddressForm,
  TVMainApplicantReviewForm,
];

const Applicant2IntroForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Intro')
);
const Applicant2NameForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Name')
);
const Applicant2ContactForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Contact')
);
const Applicant2PersonalForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Personal')
);
const Applicant2TaxesForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Taxes')
);
const Applicant2FamilyForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Family')
);
const Applicant2ChildrenForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Children')
);
const Applicant2ResidenceStatusForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/ResidenceStatus')
);
const Applicant2ResidenceStatusATForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/ResidenceStatusAT')
);
const Applicant2ResidenceAddressForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/ResidenceAddress')
);
const Applicant2EmploymentForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Employment')
);
const Applicant2SelfEmployedForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/SelfEmployed')
);
const Applicant2EmploymentTemporaryForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/EmploymentTemporary')
);
const Applicant2EmploymentTrialForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/EmploymentTrial')
);
const Applicant2IncomeForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Income')
);
const Applicant2AdditionalIncomeForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/AdditionalIncome')
);
const Applicant2MonthlyCostsForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/MonthlyCosts')
);
const Applicant2PensionForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Pension')
);
const Applicant2InsuranceForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Insurance')
);
const Applicant2InsuranceAdditionalForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/InsuranceAdditional')
);
const Applicant2CarsForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Cars')
);
const Applicant2LeasingForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Leasing')
);
const Applicant2LoansForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Loans')
);
const Applicant2Loan1Form = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Loan1')
);
const Applicant2Loan2Form = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Loan2')
);
const Applicant2Loan3Form = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Loan3')
);
const Applicant2AssetsForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Assets')
);
const Applicant2ReviewForm = ReactLazyPreload(
  () => import('pages/Forms/Applicant2/Review')
);
export const Applicant2SectionScreens = [
  Applicant2IntroForm,
  Applicant2NameForm,
  Applicant2ContactForm,
  Applicant2PersonalForm,
  Applicant2TaxesForm,
  Applicant2FamilyForm,
  Applicant2ChildrenForm,
  Applicant2ResidenceStatusForm,
  Applicant2ResidenceStatusATForm,
  Applicant2ResidenceAddressForm,
  Applicant2EmploymentForm,
  Applicant2SelfEmployedForm,
  Applicant2EmploymentTemporaryForm,
  Applicant2EmploymentTrialForm,
  Applicant2IncomeForm,
  Applicant2AdditionalIncomeForm,
  Applicant2MonthlyCostsForm,
  Applicant2PensionForm,
  Applicant2InsuranceForm,
  Applicant2InsuranceAdditionalForm,
  Applicant2CarsForm,
  Applicant2LeasingForm,
  Applicant2LoansForm,
  Applicant2Loan1Form,
  Applicant2Loan2Form,
  Applicant2Loan3Form,
  Applicant2AssetsForm,
  Applicant2ReviewForm,
];

const NewPropertyIntroForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/Intro')
);
const NewPropertyStatusForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/Status')
);
const NewPropertyDetailsForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/Details')
);
const NewPropertyAddressForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/Address')
);
const NewPropertyUsageForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/Usage')
);
const NewPropertyRentedForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/Rented')
);
const NewPropertyRestrictionsForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/Restrictions')
);
const NewPropertyLandRegisterForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/LandRegister')
);
const NewPropertyConstructionForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/Construction')
);
const NewPropertyHouseDetailsForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/HouseDetails')
);
const NewPropertyApartment1Form = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/ApartmentDetails1')
);
const NewPropertyApartment2Form = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/ApartmentDetails2')
);
const NewPropertyBuildingsForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/BuildingsDetails')
);
const NewPropertyValuationForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/UserValuation')
);
const NewPropertyValuationConstructionForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/ValuationConstruction')
);
const NewPropertyReviewForm = ReactLazyPreload(
  () => import('pages/Forms/NewProperty/Review')
);
export const NewPropertySectionScreens = [
  NewPropertyIntroForm,
  NewPropertyStatusForm,
  NewPropertyDetailsForm,
  NewPropertyAddressForm,
  NewPropertyUsageForm,
  NewPropertyRentedForm,
  NewPropertyRestrictionsForm,
  NewPropertyLandRegisterForm,
  NewPropertyConstructionForm,
  NewPropertyHouseDetailsForm,
  NewPropertyApartment1Form,
  NewPropertyApartment2Form,
  NewPropertyBuildingsForm,
  NewPropertyValuationForm,
  NewPropertyValuationConstructionForm,
  NewPropertyReviewForm,
];

const NoPermissionForm = ReactLazyPreload(
  () => import('pages/Forms/Exit/NoPermission')
);
const NoStepSuppliedFromServerForm = ReactLazyPreload(
  () => import('pages/Forms/Exit/NoStepSuppliedFromServer')
);
const SurveyForm = ReactLazyPreload(() => import('pages/Forms/Exit/Survey'));
const TVSurveyForm = ReactLazyPreload(
  () => import('pages/Forms/Exit/TVSurvey')
);
const SchufaEndForm = ReactLazyPreload(
  () => import('pages/Forms/Exit/SchufaEnd')
);
const ThankYouEndForm = ReactLazyPreload(
  () => import('pages/Forms/Exit/ThankYouEnd')
);

export enum FormSteps {
  Start = 'start',
  TVStart = 'tv_start',
  ApplicationIntro = 'application/intro',
  ApplicationPurpose = 'application/purpose',
  ApplicationPreferences = 'application/preferences',
  ApplicationDetails = 'application/details',
  ApplicationApplicants = 'application/applicants',
  ApplicationEquityRelease = 'application/equity_release',
  ApplicationNewBuild = 'application/new_build',
  ApplicationReview = 'application/review',
  TVApplicationIntro = 'tv_application/intro',
  TVApplicationPreferences = 'tv_application/preferences',
  TVApplicationOptions = 'tv_application/options',
  TVApplicationQuestions = 'tv_application/questions',
  TVApplicationReview = 'tv_application/review',
  TVPropertyIntro = 'tv_property/intro',
  TVPropertyDetails = 'tv_property/details',
  TVPropertyAddress = 'tv_property/address',
  TVPropertyUsage = 'tv_property/usage',
  TVPropertyOtherAreas = 'tv_property/other_areas',
  TVPropertyAdditionalDetails = 'tv_property/additional_details',
  TVPropertyParking = 'tv_property/parking',
  TVPropertyUserValuation = 'tv_property/user_valuation',
  TVPropertyRestrictions = 'tv_property/restrictions',
  TVPropertyOwnership = 'tv_property/ownership',
  TVPropertyLoan = 'tv_property/property_loan',
  TVPropertyReview = 'tv_property/review',
  MainPropertyIntro = 'property/intro',
  MainPropertyDetails = 'property/details',
  MainPropertyAddress = 'property/address',
  MainPropertyUsage = 'property/usage',
  MainPropertyRented = 'property/rented',
  MainPropertyValuation = 'property/user_valuation',
  MainPropertyRestrictions = 'property/restrictions',
  MainPropertyLeasehold = 'property/leasehold',
  MainPropertyLandRegister = 'property/land_register',
  MainPropertyConstruction = 'property/construction',
  MainPropertyHouse = 'property/house_details',
  MainPropertyApartment1 = 'property/apartment_details_1',
  MainPropertyApartment2 = 'property/apartment_details_2',
  MainPropertyBuildings = 'property/buildings_details',
  MainPropertyBuildingsUnits = 'property/buildings_units',
  MainPropertyUnitsResidential = 'property/units_residential',
  MainPropertyUnitsCommercial = 'property/units_commercial',
  MainPropertyBathroom = 'property/bathroom',
  MainPropertyFacilities = 'property/facilities',
  PropertyDebtIntro = 'property_debt/intro',
  PropertyDebtOverview = 'property_debt/overview',
  PropertyDebtMortgage1 = 'property_debt/mortgage_1',
  PropertyDebtMortgage2 = 'property_debt/mortgage_2',
  PropertyDebtConstructionLoan = 'property_debt/construction_loan',
  PropertyDebtReview = 'property_debt/review',
  MainPropertyReview = 'property/review',
  RealEstateIntro = 'real_estate/intro',
  RealEstateNumber = 'real_estate/number',
  RealEstateAddress1 = 'real_estate/address/1',
  RealEstateDetails1 = 'real_estate/details/1',
  RealEstateUsage1 = 'real_estate/usage/1',
  RealEstateLoan1 = 'real_estate/loan/1',
  RealEstateAddress2 = 'real_estate/address/2',
  RealEstateDetails2 = 'real_estate/details/2',
  RealEstateUsage2 = 'real_estate/usage/2',
  RealEstateLoan2 = 'real_estate/loan/2',
  RealEstateAddress3 = 'real_estate/address/3',
  RealEstateDetails3 = 'real_estate/details/3',
  RealEstateUsage3 = 'real_estate/usage/3',
  RealEstateLoan3 = 'real_estate/loan/3',
  RealEstateReview = 'real_estate/review',
  MainApplicantIntro = 'main_applicant/intro',
  MainApplicantName = 'main_applicant/name',
  MainApplicantContact = 'main_applicant/contact_details',
  MainApplicantPersonal = 'main_applicant/personal',
  MainApplicantTaxes = 'main_applicant/taxes',
  MainApplicantFamily = 'main_applicant/family',
  MainApplicantChildren = 'main_applicant/children',
  MainApplicantResidenceStatus = 'main_applicant/residence_status',
  MainApplicantResidenceStatusAT = 'main_applicant/residence_status_at',
  MainApplicantResidenceAddress = 'main_applicant/residence_address',
  MainApplicantEmployment = 'main_applicant/employment',
  MainApplicantSelfEmployed = 'main_applicant/self_employed',
  MainApplicantEmploymentTemporary = 'main_applicant/employment_temporary',
  MainApplicantEmploymentTrial = 'main_applicant/employment_trial',
  MainApplicantIncome = 'main_applicant/income',
  MainApplicantAdditionalIncome = 'main_applicant/additional_incomes',
  MainApplicantMonthlyCosts = 'main_applicant/monthly_costs',
  MainApplicantPension = 'main_applicant/pension',
  MainApplicantInsurance = 'main_applicant/insurance',
  MainApplicantInsuranceAdditional = 'main_applicant/insurance_additional',
  MainApplicantCars = 'main_applicant/cars',
  MainApplicantLeasing = 'main_applicant/leasing',
  MainApplicantLoans = 'main_applicant/private_loans',
  MainApplicantLoan1 = 'main_applicant/loan/1',
  MainApplicantLoan2 = 'main_applicant/loan/2',
  MainApplicantLoan3 = 'main_applicant/loan/3',
  MainApplicantAssets = 'main_applicant/assets',
  MainApplicantReview = 'main_applicant/review',
  TVMainApplicantIntro = 'tv_main_applicant/intro',
  TVMainApplicantName = 'tv_main_applicant/name',
  TVMainApplicantContactDetails = 'tv_main_applicant/contact_details',
  TVMainApplicantDetails = 'tv_main_applicant/details',
  TVMainApplicantEmployment = 'tv_main_applicant/employment',
  TVMainApplicantResidenceAddress = 'tv_main_applicant/residence_address',
  TVMainApplicantReview = 'tv_main_applicant/review',
  Applicant2Intro = 'applicant_2/intro',
  Applicant2Name = 'applicant_2/name',
  Applicant2Contact = 'applicant_2/contact_details',
  Applicant2Personal = 'applicant_2/personal',
  Applicant2Taxes = 'applicant_2/taxes',
  Applicant2Family = 'applicant_2/family',
  Applicant2Children = 'applicant_2/children',
  Applicant2ResidenceStatus = 'applicant_2/residence_status',
  Applicant2ResidenceStatusAT = 'applicant_2/residence_status_at',
  Applicant2ResidenceAddress = 'applicant_2/residence_address',
  Applicant2Employment = 'applicant_2/employment',
  Applicant2SelfEmployed = 'applicant_2/self_employed',
  Applicant2EmploymentTemporary = 'applicant_2/employment_temporary',
  Applicant2EmploymentTrial = 'applicant_2/employment_trial',
  Applicant2Income = 'applicant_2/income',
  Applicant2AdditionalIncome = 'applicant_2/additional_incomes',
  Applicant2MonthlyCosts = 'applicant_2/monthly_costs',
  Applicant2Pension = 'applicant_2/pension',
  Applicant2Insurance = 'applicant_2/insurance',
  Applicant2InsuranceAdditional = 'applicant_2/insurance_additional',
  Applicant2Cars = 'applicant_2/cars',
  Applicant2Leasing = 'applicant_2/leasing',
  Applicant2Loans = 'applicant_2/private_loans',
  Applicant2Loan1 = 'applicant_2/loan/1',
  Applicant2Loan2 = 'applicant_2/loan/2',
  Applicant2Loan3 = 'applicant_2/loan/3',
  Applicant2Assets = 'applicant_2/assets',
  Applicant2Review = 'applicant_2/review',
  NewPropertyIntro = 'new_property/intro',
  NewPropertyStatus = 'new_property/status',
  NewPropertyDetails = 'new_property/details',
  NewPropertyAddress = 'new_property/address',
  NewPropertyUsage = 'new_property/usage',
  NewPropertyRented = 'new_property/rented',
  NewPropertyRestrictions = 'new_property/restrictions',
  NewPropertyLandRegister = 'new_property/land_register',
  NewPropertyConstruction = 'new_property/construction',
  NewPropertyHouseDetails = 'new_property/house_details',
  NewPropertyApartment1 = 'new_property/apartment_details_1',
  NewPropertyApartment2 = 'new_property/apartment_details_2',
  NewPropertyBuildings = 'new_property/buildings_details',
  NewPropertyValuation = 'new_property/valuation_purchase',
  NewPropertyValuationConstruction = 'new_property/valuation_construction',
  NewPropertyReview = 'new_property/review',
  NoPermission = 'no_permission',
  NoStepSuppliedFromServer = 'no_step_supplied_from_server',
  Survey = 'survey',
  TVSurvey = 'tv_survey',
  ThankYouEnd = 'thank_you',
  SchufaEnd = 'negative_schufa_exit',
}

const forms = {
  start: FormSteps.Start,
  steps: {
    [FormSteps.Start]: {
      name: FormSteps.Start,
      component: StartForm,
      isBackButtonVisible: false,
      hideProgressIndicatorMobileMode: true,
    },
    [FormSteps.TVStart]: {
      name: FormSteps.TVStart,
      component: TVStartForm,
      isBackButtonVisible: false,
      hideProgressIndicatorMobileMode: true,
    },
    [FormSteps.ApplicationIntro]: {
      name: FormSteps.ApplicationIntro,
      component: ApplicationIntroForm,
    },
    [FormSteps.ApplicationPurpose]: {
      name: FormSteps.ApplicationPurpose,
      component: ApplicationPurposeForm,
    },
    [FormSteps.ApplicationPreferences]: {
      name: FormSteps.ApplicationPreferences,
      component: ApplicationPreferencesForm,
    },
    [FormSteps.ApplicationDetails]: {
      name: FormSteps.ApplicationDetails,
      component: ApplicationDetailsForm,
    },
    [FormSteps.ApplicationApplicants]: {
      name: FormSteps.ApplicationApplicants,
      component: ApplicationApplicantsForm,
    },
    [FormSteps.ApplicationEquityRelease]: {
      name: FormSteps.ApplicationEquityRelease,
      component: ApplicationEquityReleaseForm,
    },
    [FormSteps.ApplicationNewBuild]: {
      name: FormSteps.ApplicationNewBuild,
      component: ApplicationNewBuildForm,
    },
    [FormSteps.ApplicationReview]: {
      name: FormSteps.ApplicationReview,
      component: ApplicationReviewForm,
    },
    [FormSteps.TVApplicationIntro]: {
      name: FormSteps.TVApplicationIntro,
      component: TVApplicationIntroForm,
    },
    [FormSteps.TVApplicationPreferences]: {
      name: FormSteps.TVApplicationPreferences,
      component: TVApplicationPreferencesForm,
    },
    [FormSteps.TVApplicationOptions]: {
      name: FormSteps.TVApplicationOptions,
      component: TVApplicationOptionsForm,
    },
    [FormSteps.TVApplicationQuestions]: {
      name: FormSteps.TVApplicationQuestions,
      component: TVApplicationQuestionsForm,
    },
    [FormSteps.TVApplicationReview]: {
      name: FormSteps.TVApplicationReview,
      component: TVApplicationReviewForm,
    },
    [FormSteps.TVPropertyIntro]: {
      name: FormSteps.TVPropertyIntro,
      component: TVPropertyIntroForm,
      isBackButtonVisible: false,
    },
    [FormSteps.TVPropertyDetails]: {
      name: FormSteps.TVPropertyDetails,
      component: TVPropertyDetailsForm,
    },
    [FormSteps.TVPropertyAddress]: {
      name: FormSteps.TVPropertyAddress,
      component: TVPropertyAddressForm,
    },
    [FormSteps.TVPropertyUsage]: {
      name: FormSteps.TVPropertyUsage,
      component: TVPropertyUsageForm,
    },
    [FormSteps.TVPropertyOtherAreas]: {
      name: FormSteps.TVPropertyOtherAreas,
      component: TVPropertyOtherAreasForm,
    },
    [FormSteps.TVPropertyAdditionalDetails]: {
      name: FormSteps.TVPropertyAdditionalDetails,
      component: TVPropertyAdditionalDetailsForm,
    },
    [FormSteps.TVPropertyParking]: {
      name: FormSteps.TVPropertyParking,
      component: TVPropertyParkingForm,
    },
    [FormSteps.TVPropertyUserValuation]: {
      name: FormSteps.TVPropertyUserValuation,
      component: TVPropertyUserValuationForm,
    },
    [FormSteps.TVPropertyRestrictions]: {
      name: FormSteps.TVPropertyRestrictions,
      component: TVPropertyRestrictionsForm,
    },
    [FormSteps.TVPropertyOwnership]: {
      name: FormSteps.TVPropertyOwnership,
      component: TVPropertyOwnershipForm,
    },
    [FormSteps.TVPropertyLoan]: {
      name: FormSteps.TVPropertyLoan,
      component: TVPropertyLoanForm,
    },
    [FormSteps.TVPropertyReview]: {
      name: FormSteps.TVPropertyReview,
      component: TVPropertyReviewForm,
    },
    [FormSteps.MainPropertyIntro]: {
      name: FormSteps.MainPropertyIntro,
      component: MainPropertyIntroForm,
      isBackButtonVisible: false,
    },
    [FormSteps.MainPropertyDetails]: {
      name: FormSteps.MainPropertyDetails,
      component: MainPropertyDetailsForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyAddress]: {
      name: FormSteps.MainPropertyAddress,
      component: MainPropertyAddressForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyUsage]: {
      name: FormSteps.MainPropertyUsage,
      component: MainPropertyUsageForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyRented]: {
      name: FormSteps.MainPropertyRented,
      component: MainPropertyRentedForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyValuation]: {
      name: FormSteps.MainPropertyValuation,
      component: MainPropertyValuationForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyRestrictions]: {
      name: FormSteps.MainPropertyRestrictions,
      component: MainPropertyRestrictionsForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyLeasehold]: {
      name: FormSteps.MainPropertyLeasehold,
      component: MainPropertyLeaseholdForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyLandRegister]: {
      name: FormSteps.MainPropertyLandRegister,
      component: MainPropertyLandRegisterForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyConstruction]: {
      name: FormSteps.MainPropertyConstruction,
      component: MainPropertyConstructionForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyHouse]: {
      name: FormSteps.MainPropertyHouse,
      component: MainPropertyHouseForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyApartment1]: {
      name: FormSteps.MainPropertyApartment1,
      component: MainPropertyApartment1Form,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyApartment2]: {
      name: FormSteps.MainPropertyApartment2,
      component: MainPropertyApartment2Form,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyBuildings]: {
      name: FormSteps.MainPropertyBuildings,
      component: MainPropertyBuildingsForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyBuildingsUnits]: {
      name: FormSteps.MainPropertyBuildingsUnits,
      component: MainPropertyBuildingsUnitsForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyUnitsResidential]: {
      name: FormSteps.MainPropertyUnitsResidential,
      component: MainPropertyUnitsResidentialForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyUnitsCommercial]: {
      name: FormSteps.MainPropertyUnitsCommercial,
      component: MainPropertyUnitsCommercialForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyBathroom]: {
      name: FormSteps.MainPropertyBathroom,
      component: MainPropertyBathroomForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyFacilities]: {
      name: FormSteps.MainPropertyFacilities,
      component: MainPropertyFacilitiesForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainPropertyReview]: {
      name: FormSteps.MainPropertyReview,
      component: MainPropertyReviewForm,

      isBackButtonVisible: true,
    },
    [FormSteps.PropertyDebtIntro]: {
      name: FormSteps.PropertyDebtIntro,
      component: PropertyDebtIntroForm,

      isBackButtonVisible: false,
    },
    [FormSteps.PropertyDebtOverview]: {
      name: FormSteps.PropertyDebtOverview,
      component: PropertyDebtOverviewForm,

      isBackButtonVisible: true,
    },
    [FormSteps.PropertyDebtMortgage1]: {
      name: FormSteps.PropertyDebtMortgage1,
      component: PropertyDebtMortgage1Form,

      isBackButtonVisible: true,
    },
    [FormSteps.PropertyDebtMortgage2]: {
      name: FormSteps.PropertyDebtMortgage2,
      component: PropertyDebtMortgage2Form,

      isBackButtonVisible: true,
    },
    [FormSteps.PropertyDebtConstructionLoan]: {
      name: FormSteps.PropertyDebtConstructionLoan,
      component: PropertyDebtConstructionLoanForm,

      isBackButtonVisible: true,
    },
    [FormSteps.PropertyDebtReview]: {
      name: FormSteps.PropertyDebtReview,
      component: PropertyDebtReviewForm,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateIntro]: {
      name: FormSteps.RealEstateIntro,
      component: RealEstateIntroForm,

      isBackButtonVisible: false,
    },
    [FormSteps.RealEstateNumber]: {
      name: FormSteps.RealEstateNumber,
      component: RealEstateNumberForm,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateAddress1]: {
      name: FormSteps.RealEstateAddress1,
      component: RealEstateAddress1Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateDetails1]: {
      name: FormSteps.RealEstateDetails1,
      component: RealEstateDetails1Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateUsage1]: {
      name: FormSteps.RealEstateUsage1,
      component: RealEstateUsage1Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateLoan1]: {
      name: FormSteps.RealEstateLoan1,
      component: RealEstateLoan1Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateAddress2]: {
      name: FormSteps.RealEstateAddress2,
      component: RealEstateAddress2Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateDetails2]: {
      name: FormSteps.RealEstateDetails2,
      component: RealEstateDetails2Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateUsage2]: {
      name: FormSteps.RealEstateUsage2,
      component: RealEstateUsage2Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateLoan2]: {
      name: FormSteps.RealEstateLoan2,
      component: RealEstateLoan2Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateAddress3]: {
      name: FormSteps.RealEstateAddress3,
      component: RealEstateAddress3Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateDetails3]: {
      name: FormSteps.RealEstateDetails3,
      component: RealEstateDetails3Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateUsage3]: {
      name: FormSteps.RealEstateUsage3,
      component: RealEstateUsage3Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateLoan3]: {
      name: FormSteps.RealEstateLoan3,
      component: RealEstateLoan3Form,

      isBackButtonVisible: true,
    },
    [FormSteps.RealEstateReview]: {
      name: FormSteps.RealEstateReview,
      component: RealEstateReviewForm,

      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantIntro]: {
      name: FormSteps.MainApplicantIntro,
      component: MainApplicantIntroForm,
      isBackButtonVisible: false,
    },
    [FormSteps.MainApplicantName]: {
      name: FormSteps.MainApplicantName,
      component: MainApplicantNameForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantContact]: {
      name: FormSteps.MainApplicantContact,
      component: MainApplicantContactForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantPersonal]: {
      name: FormSteps.MainApplicantPersonal,
      component: MainApplicantPersonalForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantTaxes]: {
      name: FormSteps.MainApplicantTaxes,
      component: MainApplicantTaxesForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantFamily]: {
      name: FormSteps.MainApplicantFamily,
      component: MainApplicantFamilyForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantChildren]: {
      name: FormSteps.MainApplicantChildren,
      component: MainApplicantChildrenForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantResidenceStatus]: {
      name: FormSteps.MainApplicantResidenceStatus,
      component: MainApplicantResidenceStatusForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantResidenceStatusAT]: {
      name: FormSteps.MainApplicantResidenceStatusAT,
      component: MainApplicantResidenceStatusATForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantResidenceAddress]: {
      name: FormSteps.MainApplicantResidenceAddress,
      component: MainApplicantResidenceAddressForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantEmployment]: {
      name: FormSteps.MainApplicantEmployment,
      component: MainApplicantEmploymentForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantSelfEmployed]: {
      name: FormSteps.MainApplicantSelfEmployed,
      component: MainApplicantSelfEmployedForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantEmploymentTemporary]: {
      name: FormSteps.MainApplicantEmploymentTemporary,
      component: MainApplicantEmploymentTemporaryForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantEmploymentTrial]: {
      name: FormSteps.MainApplicantEmploymentTrial,
      component: MainApplicantEmploymentTrialForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantIncome]: {
      name: FormSteps.MainApplicantIncome,
      component: MainApplicantIncomeForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantAdditionalIncome]: {
      name: FormSteps.MainApplicantAdditionalIncome,
      component: MainApplicantAdditionalIncomeForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantMonthlyCosts]: {
      name: FormSteps.MainApplicantMonthlyCosts,
      component: MainApplicantMonthlyCostsForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantPension]: {
      name: FormSteps.MainApplicantPension,
      component: MainApplicantPensionForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantInsurance]: {
      name: FormSteps.MainApplicantInsurance,
      component: MainApplicantInsuranceForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantInsuranceAdditional]: {
      name: FormSteps.MainApplicantInsuranceAdditional,
      component: MainApplicantInsuranceAdditionalForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantCars]: {
      name: FormSteps.MainApplicantCars,
      component: MainApplicantCarsForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantLeasing]: {
      name: FormSteps.MainApplicantLeasing,
      component: MainApplicantLeasingForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantLoans]: {
      name: FormSteps.MainApplicantLoans,
      component: MainApplicantLoansForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantLoan1]: {
      name: FormSteps.MainApplicantLoan1,
      component: MainApplicantLoan1Form,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantLoan2]: {
      name: FormSteps.MainApplicantLoan2,
      component: MainApplicantLoan2Form,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantLoan3]: {
      name: FormSteps.MainApplicantLoan3,
      component: MainApplicantLoan3Form,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantAssets]: {
      name: FormSteps.MainApplicantAssets,
      component: MainApplicantAssetsForm,
      isBackButtonVisible: true,
    },
    [FormSteps.MainApplicantReview]: {
      name: FormSteps.MainApplicantReview,
      component: MainApplicantReviewForm,
      isBackButtonVisible: true,
    },
    [FormSteps.TVMainApplicantIntro]: {
      name: FormSteps.TVMainApplicantIntro,
      component: TVMainApplicantIntroForm,
      isBackButtonVisible: false,
    },
    [FormSteps.TVMainApplicantName]: {
      name: FormSteps.TVMainApplicantName,
      component: TVMainApplicantNameForm,
    },
    [FormSteps.TVMainApplicantContactDetails]: {
      name: FormSteps.TVMainApplicantContactDetails,
      component: TVMainApplicantContactDetailsForm,
    },
    [FormSteps.TVMainApplicantDetails]: {
      name: FormSteps.TVMainApplicantDetails,
      component: TVMainApplicantDetailsForm,
    },
    [FormSteps.TVMainApplicantEmployment]: {
      name: FormSteps.TVMainApplicantEmployment,
      component: TVMainApplicantEmploymentForm,
    },
    [FormSteps.TVMainApplicantResidenceAddress]: {
      name: FormSteps.TVMainApplicantResidenceAddress,
      component: TVMainApplicantResidenceAddressForm,
    },
    [FormSteps.TVMainApplicantReview]: {
      name: FormSteps.TVMainApplicantReview,
      component: TVMainApplicantReviewForm,
    },
    [FormSteps.Applicant2Intro]: {
      name: FormSteps.Applicant2Intro,
      component: Applicant2IntroForm,

      isBackButtonVisible: false,
    },
    [FormSteps.Applicant2Name]: {
      name: FormSteps.Applicant2Name,
      component: Applicant2NameForm,
    },
    [FormSteps.Applicant2Contact]: {
      name: FormSteps.Applicant2Contact,
      component: Applicant2ContactForm,
    },
    [FormSteps.Applicant2Personal]: {
      name: FormSteps.Applicant2Personal,
      component: Applicant2PersonalForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Taxes]: {
      name: FormSteps.Applicant2Taxes,
      component: Applicant2TaxesForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Family]: {
      name: FormSteps.Applicant2Family,
      component: Applicant2FamilyForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Children]: {
      name: FormSteps.Applicant2Children,
      component: Applicant2ChildrenForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2ResidenceStatus]: {
      name: FormSteps.Applicant2ResidenceStatus,
      component: Applicant2ResidenceStatusForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2ResidenceStatusAT]: {
      name: FormSteps.Applicant2ResidenceStatusAT,
      component: Applicant2ResidenceStatusATForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2ResidenceAddress]: {
      name: FormSteps.Applicant2ResidenceAddress,
      component: Applicant2ResidenceAddressForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Employment]: {
      name: FormSteps.Applicant2Employment,
      component: Applicant2EmploymentForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2SelfEmployed]: {
      name: FormSteps.Applicant2SelfEmployed,
      component: Applicant2SelfEmployedForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2EmploymentTemporary]: {
      name: FormSteps.Applicant2EmploymentTemporary,
      component: Applicant2EmploymentTemporaryForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2EmploymentTrial]: {
      name: FormSteps.Applicant2EmploymentTrial,
      component: Applicant2EmploymentTrialForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Income]: {
      name: FormSteps.Applicant2Income,
      component: Applicant2IncomeForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2AdditionalIncome]: {
      name: FormSteps.Applicant2AdditionalIncome,
      component: Applicant2AdditionalIncomeForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2MonthlyCosts]: {
      name: FormSteps.Applicant2MonthlyCosts,
      component: Applicant2MonthlyCostsForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Pension]: {
      name: FormSteps.Applicant2Pension,
      component: Applicant2PensionForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Insurance]: {
      name: FormSteps.Applicant2Insurance,
      component: Applicant2InsuranceForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2InsuranceAdditional]: {
      name: FormSteps.Applicant2InsuranceAdditional,
      component: Applicant2InsuranceAdditionalForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Cars]: {
      name: FormSteps.Applicant2Cars,
      component: Applicant2CarsForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Leasing]: {
      name: FormSteps.Applicant2Leasing,
      component: Applicant2LeasingForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Loans]: {
      name: FormSteps.Applicant2Loans,
      component: Applicant2LoansForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Loan1]: {
      name: FormSteps.Applicant2Loan1,
      component: Applicant2Loan1Form,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Loan2]: {
      name: FormSteps.Applicant2Loan2,
      component: Applicant2Loan2Form,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Loan3]: {
      name: FormSteps.Applicant2Loan3,
      component: Applicant2Loan3Form,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Assets]: {
      name: FormSteps.Applicant2Assets,
      component: Applicant2AssetsForm,
      isBackButtonVisible: true,
    },
    [FormSteps.Applicant2Review]: {
      name: FormSteps.Applicant2Review,
      component: Applicant2ReviewForm,
      isBackButtonVisible: true,
    },
    [FormSteps.NewPropertyIntro]: {
      name: FormSteps.NewPropertyIntro,
      component: NewPropertyIntroForm,
    },
    [FormSteps.NewPropertyDetails]: {
      name: FormSteps.NewPropertyDetails,
      component: NewPropertyDetailsForm,
    },
    [FormSteps.NewPropertyStatus]: {
      name: FormSteps.NewPropertyStatus,
      component: NewPropertyStatusForm,
    },
    [FormSteps.NewPropertyAddress]: {
      name: FormSteps.NewPropertyAddress,
      component: NewPropertyAddressForm,
    },
    [FormSteps.NewPropertyUsage]: {
      name: FormSteps.NewPropertyUsage,
      component: NewPropertyUsageForm,
    },
    [FormSteps.NewPropertyRented]: {
      name: FormSteps.NewPropertyRented,
      component: NewPropertyRentedForm,
    },
    [FormSteps.NewPropertyRestrictions]: {
      name: FormSteps.NewPropertyRestrictions,
      component: NewPropertyRestrictionsForm,
    },
    [FormSteps.NewPropertyLandRegister]: {
      name: FormSteps.NewPropertyLandRegister,
      component: NewPropertyLandRegisterForm,
    },
    [FormSteps.NewPropertyConstruction]: {
      name: FormSteps.NewPropertyConstruction,
      component: NewPropertyConstructionForm,
    },
    [FormSteps.NewPropertyHouseDetails]: {
      name: FormSteps.NewPropertyHouseDetails,
      component: NewPropertyHouseDetailsForm,
    },
    [FormSteps.NewPropertyApartment1]: {
      name: FormSteps.NewPropertyApartment1,
      component: NewPropertyApartment1Form,
    },
    [FormSteps.NewPropertyApartment2]: {
      name: FormSteps.NewPropertyApartment2,
      component: NewPropertyApartment2Form,
    },
    [FormSteps.NewPropertyBuildings]: {
      name: FormSteps.NewPropertyBuildings,
      component: NewPropertyBuildingsForm,
    },
    [FormSteps.NewPropertyValuation]: {
      name: FormSteps.NewPropertyValuation,
      component: NewPropertyValuationForm,
    },
    [FormSteps.NewPropertyValuationConstruction]: {
      name: FormSteps.NewPropertyValuationConstruction,
      component: NewPropertyValuationConstructionForm,
    },
    [FormSteps.NewPropertyReview]: {
      name: FormSteps.NewPropertyReview,
      component: NewPropertyReviewForm,
    },
    [FormSteps.NoPermission]: {
      name: FormSteps.NoPermission,
      component: NoPermissionForm,
      Layout: FormFullWidthLayout,
      isProgressIndicatorVisible: false,
    },
    [FormSteps.NoStepSuppliedFromServer]: {
      name: FormSteps.NoStepSuppliedFromServer,
      component: NoStepSuppliedFromServerForm,
      Layout: FormFullWidthLayout,
      isProgressIndicatorVisible: false,
    },
    [FormSteps.Survey]: {
      name: FormSteps.Survey,
      component: SurveyForm,
      isBackButtonVisible: false,
      hideProgressIndicatorMobileMode: true,
    },
    [FormSteps.TVSurvey]: {
      name: FormSteps.TVSurvey,
      component: TVSurveyForm,
      isBackButtonVisible: false,
      hideProgressIndicatorMobileMode: true,
    },
    [FormSteps.ThankYouEnd]: {
      name: FormSteps.ThankYouEnd,
      component: ThankYouEndForm,
      Layout: FormFullWidthLayout,
      isBackButtonVisible: false,
      isProgressIndicatorVisible: false,
    },
    [FormSteps.SchufaEnd]: {
      name: FormSteps.SchufaEnd,
      component: SchufaEndForm,
      isBackButtonVisible: false,
      isProgressIndicatorVisible: false,
    },
  },
};

export default forms;
